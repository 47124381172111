import React, { useEffect, useState, useCallback } from 'react';
import useObservable from '../Hooks/useObservable.Hook';
import { getUser } from '../../Widget/Services/User.service';
import {
  getRootFolderHelper,
  getUserHelper,
  memberAuthorizationHelper,
  navigateToPricingPlansAppHelper,
} from '../../Widget/Shared/memberAuthorizationHelper';
import { setToast } from '../../Widget/Services/Toast.service';
import { TOAST } from '../../Constants/Toast.Constants';
import { getMenuSelectedItem } from '../../Widget/Services/MenuSelectedItem';

const withMemberAuthorization = (WrappedComponent: any) => {
  return (props: any) => {
    const [state, setState] = useState<any>({
      requestLoginLastValue: null,
      navigateToPricingPlansApp: null,
      user: null,
      rootFolder: null,
    });
    const [, currentItem]: any = useObservable(getMenuSelectedItem());
    const [, user]: any = useObservable(getUser());
    const memberAuthorization = async ({
      args,
      callBack,
      isAccessModal,
    }: any) => {
      if (!user?.isLoggedIn || isAccessModal) {
        state.requestLoginLastValue && state.requestLoginLastValue();
      } else {
        callBack && callBack(...args);
      }
    };
    const navigateToPricingPlansApp = async () => {
      state.navigateToPricingPlansApp &&
        state.navigateToPricingPlansApp(currentItem.pricingPlansIds);
    };

    const getRequestLoginFunction = useCallback(async () => {
      try {
        const tempResult = await memberAuthorizationHelper();
        const tempUser = await getUserHelper();
        const navigateToPricingPlansAppInstance =
          await navigateToPricingPlansAppHelper();
        const rootFolder = await getRootFolderHelper();
        setState((prev: any) => ({
          ...prev,
          user: tempUser,
          requestLoginLastValue: tempResult,
          navigateToPricingPlansApp: navigateToPricingPlansAppInstance,
          rootFolder,
        }));
      } catch (e) {
        setToast({
          skin: TOAST.error,
          content: 'member authorization error',
        });
        return e;
      }
    }, []);

    useEffect(() => {
      getRequestLoginFunction();
    }, [getRequestLoginFunction]);

    return (
      <>
        {user !== undefined && state.requestLoginLastValue !== null ? (
          <WrappedComponent
            {...props}
            memberAuthorization={memberAuthorization}
            navigateToPricingPlansApp={navigateToPricingPlansApp}
          />
        ) : null}
      </>
    );
  };
};
export default withMemberAuthorization;
