import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Text, Divider, Tabs, TabsSkin, TabsAlignment } from 'wix-ui-tpa';
import { st, classes } from './HeaderWrapper.st.css';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../Services/SelectedItems.service';
import { getRootFolder } from '../../../Services/RootFolder.service';
import { getTableSettings } from '../../../Services/TableSettings.service';
import { TableColumns } from '../../../../Constants/TableHeader.Constants';
import { Status } from '../../../../Constants/Permissions';
import { getUser } from '../../../Services/User.service';
import { getAppSettings } from '../../../Services/AppSettings.service';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  st as dividerStyle,
  classes as dividerClass,
} from '../../../Shared/SharedDivider.st.css';
import {
  getCurrentTab,
  setCurrentTab,
} from '../../../Services/MemberTab.service';
import { MemberTab } from '../../../Observables/MemberTab.observable';
import { setSelectedFolder } from '../../../Services/SelectedFolder.service';

const MembersHeaderWrapper = () => {
  const { t } = useTranslation();
  const [, currentTab = MemberTab.Uploads] = useObservable(getCurrentTab());
  const [, selectedItems]: any = useObservable(getSelectedItems());
  const [, rootFolder]: any = useObservable(getRootFolder());
  const [, tableDisplaySettings]: any = useObservable(getTableSettings());
  const [, user]: any = useObservable(getUser());
  const [, appSettings]: any = useObservable(getAppSettings());

  const isUserLoggedIn = user && user?.isLoggedIn;
  const viewPermissions =
    rootFolder &&
    rootFolder.authorizeActions?.find(
      (itemData: any) => itemData.action === 'VIEW',
    );

  const [displaySettings, setDisplaySettings] = useState({
    search: false,
  });

  const handleDisplaySettings = useCallback(() => {
    const showSearch = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.SEARCH_BAR,
    );
    setDisplaySettings({
      search: showSearch && showSearch.value,
    });
  }, [tableDisplaySettings]);

  useEffect(() => {
    if (tableDisplaySettings && tableDisplaySettings.length > 0) {
      handleDisplaySettings();
    }
  }, [handleDisplaySettings, tableDisplaySettings]);
  appSettings?.getViewedMemberId();

  const isEditorX: boolean = appSettings?.isEditor_X ?? false;
  const viewedUserId = appSettings?.viewedUserId;
  const sameUser = useMemo(
    () => user?.id === viewedUserId,
    [user, viewedUserId],
  );
  return (
    <div tabIndex={0} className={st(classes.membersPageTop)}>
      <div
        className={st(classes.headerWrapperContainer, {
          isEditorX,
          isMembersArea: true,
        })}
      >
        <Text className={st(classes.membersPageTitle)}>
          {t('widget.members.title')}
        </Text>
        <Text className={st(classes.membersPageSubtitle)}>
          {sameUser
            ? t('widget.members.description.my_uploads')
            : t('widget.members.description.uploads')}
        </Text>
      </div>
      {sameUser && (
        <Tabs
          items={[
            { title: t('widget.members.my_uploads.title'), id: 'tabMembers' },
            {
              title: t('widget.members.my_favorites.title'),
              id: 'tabFavorites',
            },
          ]}
          activeTabIndex={currentTab}
          onTabClick={(tabIndex) => {
            setCurrentTab(tabIndex);
            setSelectedFolder(rootFolder.id);
          }}
          skin={TabsSkin.fullUnderline}
          alignment={TabsAlignment.left}
          className={st(classes.tabContainer)}
        />
      )}
      {!sameUser && (
        <Divider className={dividerStyle(dividerClass.dividerStyle)} />
      )}
    </div>
  );
};

export default MembersHeaderWrapper;
