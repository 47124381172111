import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ActionsMenuLayout } from 'wix-ui-tpa';
import { classes } from './ItemActionsMenu.st.css';
import { getManageOptions } from './helper';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../../../../Services/SelectedItems.service';
import { ITEM_MENU_OPTIONS } from './Constants';
import { getSelectedFolder } from '../../../../../../Services/SelectedFolder.service';
import { getActionAuthStatus } from '../../../../TableWrapper/ItemsWrapper/Item/ItemActions/ItemActionsMenu/MenuOptions/helper';
import { isDisableTableHeader } from '../../../../../../Shared/accessModalsHelper';
import { getRootFolder } from '../../../../../../Services/RootFolder.service';
import { getIsServerError } from '../../../../../../Services/ServerError.service';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';

export const MenuOptionMobile = ({ handleOnClick }: any) => {
  const { t } = useTranslation();
  const [, selectedItems]: any = useObservable(getSelectedItems());
  const [, selectedFolder]: any = useObservable(getSelectedFolder());
  const [, rootFolder]: any = useObservable(getRootFolder());
  const [, serverError]: any = useObservable(getIsServerError());
  const [, AppSettings]: any = useObservable(getAppSettings());
  const isMembersArea = AppSettings?.isMembersArea ?? false;

  const disableSelectAll = isDisableTableHeader({
    rootFolder,
    selectedFolder,
    serverError,
  });
  const getIsDisplayed = (action: string) => {
    return selectedItems &&
      selectedFolder !== undefined &&
      selectedFolder.libraryItems &&
      selectedFolder.libraryItems.length > 0
      ? getActionAuthStatus(selectedFolder.libraryItems[0], action)
      : false;
  };
  let options = [];
  const generateOptions = () => {
    const tempManage = getManageOptions(
      t,
      selectedItems && selectedItems.length,
      isMembersArea,
    );
    options = [...tempManage];
    return options.map((option: any, index: number) => {
      let disabledWithoutSelect =
        (option.type !== ITEM_MENU_OPTIONS.SELECT_ALL &&
          selectedItems !== undefined &&
          selectedItems.length === 0) ||
        (disableSelectAll && option.type === ITEM_MENU_OPTIONS.SELECT_ALL);
      if (option.type === ITEM_MENU_OPTIONS.OPEN_IN_LIBRARY) {
        option.disabled = false;
        disabledWithoutSelect = false;
      } else {
        option.disabled =
          option.type === ITEM_MENU_OPTIONS.DELETE ||
          option.type === ITEM_MENU_OPTIONS.MOVE ||
          option.type === ITEM_MENU_OPTIONS.DOWNLOAD
            ? !getIsDisplayed(option.type)
            : false;
      }
      return (
        <ActionsMenuLayout.Item
          content={option.content}
          onClick={() => handleOnClick(option.type)}
          disabled={disabledWithoutSelect || option.disabled}
        />
      );
    });
  };

  return (
    <ActionsMenuLayout className={classes.actionsMenuLayoutContainer}>
      {selectedItems && generateOptions()}
    </ActionsMenuLayout>
  );
};
