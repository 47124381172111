import React, { useEffect, useState } from 'react';
import { Checkbox } from 'wix-ui-tpa';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import {
  getSelectedItems,
  handleSelectedItemsChange,
} from '../../../../Services/SelectedItems.service';
import { SELECTED_ITEMS_BEHAVIOR } from '../../../../../Constants/SelectedItems.Constants';
import { st, classes } from './CheckBoxComponent.st.css';
import { getSelectedFolder } from '../../../../Services/SelectedFolder.service';

const CheckBoxComponent = ({ disabledCheckBox }: any) => {
  const [error1, selectedItems]: any = useObservable(getSelectedItems());

  const [error2, selectedFolder]: any = useObservable(getSelectedFolder());

  const [state, setState] = useState({
    checked: false,
    indeterminate: false,
  });

  useEffect(() => {
    const item = document.getElementById('selectAllCheckbox');
    item
      ?.querySelector(':nth-child(1) > input')
      ?.setAttribute('tabIndex', '-1');
    if (selectedFolder && selectedItems && selectedFolder.libraryItems) {
      if (
        selectedItems.length > 0 &&
        selectedItems.length < selectedFolder?.libraryItems.length
      ) {
        setState((prev) => ({ ...prev, checked: false, indeterminate: true }));
      } else if (
        selectedItems.length > 0 &&
        selectedItems.length === selectedFolder?.libraryItems.length
      ) {
        setState((prev) => ({ ...prev, checked: true, indeterminate: false }));
      } else {
        setState((prev) => ({ ...prev, checked: false, indeterminate: false }));
      }
    }
  }, [selectedItems, selectedFolder]);

  const handleOnChange = (e: any) => {
    if (state.checked === true && state.indeterminate === false) {
      handleSelectedItemsChange(SELECTED_ITEMS_BEHAVIOR.DESELECT_ALL);
    } else if (
      (state.checked === false && state.indeterminate === true) ||
      (state.checked === false && state.indeterminate === false)
    ) {
      handleSelectedItemsChange(SELECTED_ITEMS_BEHAVIOR.SELECT_ALL);
    }
  };
  const handleOnKeyDown = (event: any) => {
    const enterBarKey = 'Enter';
    if (event.key && event.key !== enterBarKey) {
      return;
    } else {
      handleOnChange(event);
    }
  };
  return (
    <div
      role="checkbox"
      id="selectAllCheckbox"
      aria-checked={state.checked}
      tabIndex={0}
      aria-label="select all checkbox"
      onKeyDown={handleOnKeyDown}
    >
      <Checkbox
        disabled={disabledCheckBox}
        className={st(classes.checkBoxContainer)}
        checked={state.checked}
        indeterminate={state.indeterminate}
        onChange={({ checked }) => handleOnChange(checked)}
      />
    </div>
  );
};
export default CheckBoxComponent;
