import React, { useEffect, useState } from 'react';
import { classes } from './ItemName.st.css';
import ItemNameIcon from './ItemNameIcon';
import ItemNameTitle from './ItemNameTitle';
import ItemNameSubTitle from './ItemNameSubtitle';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getItemRenameStatus } from '../../../../../../Services/ItemRename.service';
import { getTableSettings } from '../../../../../../Services/TableSettings.service';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';
import { ItemAvailabilityBadges } from '../ItemAvailabilityBadges/ItemAvailabilityBadges';
import { Availability } from '../../../../../../../Constants/Permissions';

const ItemName = (props: any) => {
  const [, renameInfo]: any = useObservable(getItemRenameStatus());
  const [, tableSettings]: any = useObservable(getTableSettings());
  const [, AppSettings]: any = useObservable(getAppSettings());

  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const { item, handleChangeFolder } = props;
  const [state, setState] = useState({
    isShow: false,
  });

  const _setIsShown = () => {
    const tempIsShowItem = tableSettings.filter((item: any) => {
      if (item.id === 'FILE_SIZE_NUMBER_OF_ITEMS') {
        return item;
      }
    });
    if (tempIsShowItem && tempIsShowItem.length > 0) {
      setState((prev) => ({ ...prev, isShow: tempIsShowItem[0].value }));
    }
  };

  useEffect(() => {
    if (tableSettings && tableSettings.length > 0) {
      _setIsShown();
    }
  }, [tableSettings]);

  const handleOnClick = () => {
    if (
      renameInfo &&
      renameInfo?.renamingStatus === true &&
      item.id === renameInfo?.folderId
    ) {
    } else {
      handleChangeFolder(item);
    }
  };

  return (
    <>
      {tableSettings && tableSettings.length > 0 ? (
        <div
          className={
            classes.itemNameContainer +
            ' ' +
            (isMobile ? classes.itemNameContainerMobileView : '')
          }
          onClick={handleOnClick}
        >
          <div className={classes.itemNameIcon}>
            <ItemNameIcon item={item} />
          </div>
          <div
            className={classes.itemNameContent}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleOnClick();
              }
            }}
          >
            <div className={classes.itemNameTitle}>
              {renameInfo &&
              renameInfo.renamingStatus === true &&
              renameInfo.folderId === item.id ? (
                <ItemNameTitle item={item} renameInfo={renameInfo} />
              ) : (
                <ItemNameTitle item={item} />
              )}
            </div>
            {renameInfo &&
            renameInfo?.renamingStatus === true &&
            item.id === renameInfo?.folderId ? null : state.isShow === true ? (
              <div className={classes.itemNameSubTitle}>
                <ItemNameSubTitle item={item} />
              </div>
            ) : null}
            <ItemAvailabilityBadges
              availability={item.availability as Availability}
              path={item.path}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ItemName;
