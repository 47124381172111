import React, { useCallback, useEffect, useState } from 'react';
import { st, classes as styles } from './ItemGenerator.st.css';
import ItemLastUpdated from '../ItemLastUpdated/ItemLastUpdated';
import ItemViews from '../ItemViews/ItemViews';
import ItemFavorites from '../ItemFavorites/ItemFavorites';
import ItemContributors from '../ItemContributors/ItemContributors';
import ItemActions from '../ItemActions/ItemActions';
import ItemCheckBox from '../ItemCheckBox/ItemCheckBox';
import ItemName from '../ItemName/ItemName';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import {
  addToBreadCrumbs,
  setBreadCrumbs,
} from '../../../../../../Services/BreadCrumbs.service';
import {
  setSelectedFolderId,
  viewFileService,
} from '../../../../../../Services/SelectedFolder.service';
import { ITEMTYPE } from '../../../../../../../Constants/ItemType.Constants';
import { setIsLoading } from '../../../../../../Services/Loading.service';
import { getSelectedItems } from '../../../../../../Services/SelectedItems.service';
import { getTableSettings } from '../../../../../../Services/TableSettings.service';
import {
  getSearchSubject,
  setDisableButtonsStatus,
  setSearchSubject,
} from '../../../../../../Services/Search.service';
import withMemberAuthorization from '../../../../../../../Shared/HOC/withMemberAuthorization';
import {
  Availability,
  Status,
} from '../../../../../../../Constants/Permissions';
import biService from '../../../../../../BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER } from '../../../../../../BiEvents/Constants/BiConstants';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';
import { ItemInfoMobileView } from './ItemInfoMobileView';
import { setOpenedModal } from '../../../../../../Services/Modals.service';
import { MODAL_TYPES } from '../../../../../../../Constants/Modals.Constants';
import { setMenuSelectedItem } from '../../../../../../Services/MenuSelectedItem';
import { getUser } from '../../../../../../Services/User.service';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getAddFolderStatus } from '../../../../../../Services/AddNewFolder.service';
import { AddFolderStatus } from '../../../../../../Observables/AddNewFolder.observable';

const ItemGenerator = (props: any) => {
  const { item, memberAuthorization } = props;
  const { t } = useTranslation();
  const [, user]: any = useObservable(getUser());
  const [, selectedItems]: any = useObservable(getSelectedItems());
  const [, tableSettings]: any = useObservable(getTableSettings());
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, searchObj]: any = useObservable(getSearchSubject());
  const [, addFolderStatus] = useObservable(getAddFolderStatus());
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  const isMembersArea = appSettings?.isMembersArea ?? false;
  const [state, setState] = useState({
    isShowItemLastUpdated: false,
    isShowItemViews: false,
    isShowItemFavorites: false,
    isShowItemContributors: false,
  });
  const openLoginModal = () => {
    memberAuthorization &&
      memberAuthorization({
        args: [],
        callBack: () => { },
        isAccessModal: true,
      });
  };
  const handleOnItemClick = async (receivedItem: any) => {
    await setMenuSelectedItem(receivedItem);
    const folderPermissions =
      item &&
      item.authorizeActions?.find(
        (itemData: any) => itemData.action === 'VIEW',
      );
    if (folderPermissions.status === Status.FORBIDDEN) {
      if (
        !user?.isLoggedIn &&
        receivedItem.availability !== Availability.PAID
      ) {
        openLoginModal();
      } else {
        setOpenedModal(MODAL_TYPES.NO_PERMISSION);
      }
    } else {
      handleChangeFolder(receivedItem);
    }
  };
  const handleChangeFolder = async (receivedItem: any) => {
    if (ITEMTYPE.FILE in receivedItem) {
      viewFileService(item.id);
    } else if (ITEMTYPE.FOLDER in receivedItem) {
      if (addFolderStatus !== AddFolderStatus.CLOSED) {
        return;
      }
      let newBreadCrumbs = receivedItem?.path?.map(
        (folder: { name: string; libraryItemId: string }, index: number) => {
          return {
            value: index === 0 ? t('root.folder.label') : folder.name,
            id: folder.libraryItemId,
          };
        },
      );
      if (
        !(
          searchObj &&
          searchObj?.value !== undefined &&
          searchObj?.value !== ''
        )
      ) {
        const obj = {
          value:
            receivedItem.name.length > 20
              ? `${receivedItem.name.substr(0, 17)}...`
              : receivedItem.name,
          id: receivedItem.id,
        };
        newBreadCrumbs = await addToBreadCrumbs(obj);
      } else {
        const currentFolder = {
          value:
            receivedItem.name.length > 20
              ? `${receivedItem.name.substr(0, 17)}...`
              : receivedItem.name,
          id: receivedItem.id,
        };
        newBreadCrumbs.push(currentFolder);
        setBreadCrumbs(newBreadCrumbs);
      }
      setSelectedFolderId(receivedItem.id);
      setSearchSubject({ callApi: false, value: '' });
      setDisableButtonsStatus(false);
      if (appSettings && appSettings.scrollTo) {
        appSettings.scrollTo();
      }
    }
    biService(
      {
        file_id: receivedItem?.id,
        file_name: receivedItem?.name,
        file_owner: receivedItem?.createdByName,
        file_size: receivedItem?.fileFields
          ? receivedItem?.fileFields?.size
          : receivedItem?.folderFields?.childrenCount,
        file_type: receivedItem?.fileFields
          ? receivedItem?.fileFields?.extension
          : '',
        favorites_count: receivedItem?.timesFavorited,
        is_folder: !receivedItem?.fileFields,
        views_count: receivedItem?.uniqueViews,
        is_restricted: receivedItem?.availability !== 'PUBLIC',
      },
      BI_FILE_SHARE_WIDGET_VIEW_FILE_OR_OPEN_FOLDER,
    );
    setIsLoading(true);
  };

  const _setIsShown = useCallback(() => {
    const tempIsShowItemLastUpdated = tableSettings.filter((item: any) => {
      if (item.id === 'LAST_UPDATED') {
        return item;
      }
    });
    const tempIsShowItemViews = tableSettings.filter((item: any) => {
      if (item.id === 'VIEWS_COUNTER') {
        return item;
      }
    });
    const tempIsShowItemFavorites = tableSettings.filter((item: any) => {
      if (item.id === 'FAVORITES') {
        return item;
      }
    });
    const tempIsShowItemContributors = tableSettings.filter((item: any) => {
      if (item.id === 'CONTRIBUTORS') {
        return item;
      }
    });
    if (
      tempIsShowItemLastUpdated &&
      tempIsShowItemLastUpdated.length > 0 &&
      tempIsShowItemViews &&
      tempIsShowItemViews.length > 0 &&
      tempIsShowItemFavorites &&
      tempIsShowItemFavorites.length > 0 &&
      tempIsShowItemContributors &&
      tempIsShowItemContributors.length > 0
    ) {
      setState((prev) => ({
        ...prev,
        isShowItemLastUpdated: tempIsShowItemLastUpdated[0].value,
        isShowItemViews: tempIsShowItemViews[0].value,
        isShowItemFavorites: tempIsShowItemFavorites[0].value,
        isShowItemContributors: tempIsShowItemContributors[0].value,
      }));
    }
  }, [tableSettings]);

  useEffect(() => {
    if (tableSettings && tableSettings.length > 0) {
      _setIsShown();
    }
  }, [_setIsShown, tableSettings]);
  const actionsMenu = () => {
    return (
      <div
        className={
          isMobile
            ? styles.itemGeneratorMobileContainerActions
            : styles.itemGeneratorContainerActions
        }
      >
        {selectedItems && selectedItems.length > 0 ? (
          <></>
        ) : (
          <ItemActions item={item} />
        )}
      </div>
    );
  };

  const ItemGeneratorContainerCheckBox = () => {
    return (((isMobile && selectedItems && selectedItems.length > 0) || !isMobile) ?(
      <div className={styles.itemGeneratorContainerCheckBox}>
        <ItemCheckBox item={item} />
      </div>
    ) : (
      <div
        className={isMobile ? '' : styles.itemGeneratorContainerCheckBox}
      ></div>
    ))
  };

  return (
    <div
      className={
        isMobile !== undefined && !isMobile
          ? st(styles.itemGeneratorMainContainer, { isEditorX })
          : styles.itemGeneratorMainContainerMobile
      }
    >

      {!isMembersArea && ItemGeneratorContainerCheckBox()}
      <div
        className={
          styles.itemGeneratorContainerName +
          ' ' +
          (isMobile ? styles.itemGeneratorContainerNameMobileView : '')
        }
      >
        <div
          className={
            isMobile
              ? styles.itemNameAndActionsMenuContainerMobile
              : styles.itemNameAndActionsMenuContainer
          }
        >
          <ItemName item={item} handleChangeFolder={handleOnItemClick} />
          {isMobile && actionsMenu()}
        </div>
        <div>
          {isMobile && (
            <div className={styles.itemGeneratorContainerMobileView}>
              <ItemInfoMobileView item={item} isMembersArea={isMembersArea} />
            </div>
          )}
        </div>
      </div>
      {state.isShowItemLastUpdated === true && isMobile === false ? (
        <div className={styles.itemGeneratorContainerLastUpdated}>
          <ItemLastUpdated item={item} />
        </div>
      ) : null}

      {state.isShowItemViews === true && isMobile === false ? (
        <div className={styles.itemGeneratorContainerViews}>
          <ItemViews item={item} />
        </div>
      ) : null}

      {state.isShowItemFavorites === true && isMobile === false ? (
        <div className={styles.itemGeneratorContainerFavorites}>
          <ItemFavorites item={item} />
        </div>
      ) : null}

      {state.isShowItemContributors === true && isMobile === false ? (
        <div className={styles.itemGeneratorContainerContributors}>
          <ItemContributors item={item} isMembersArea={isMembersArea} />
        </div>
      ) : null}

      {!isMobile && actionsMenu()}
    </div>
  );
};

export default withMemberAuthorization(ItemGenerator);
