import { take, lastValueFrom } from 'rxjs';
import {
  getRequestLogin$,
  getUser$,
  setRequestLogin$,
  setUser$,
  setNavigateToPricingPlans$,
  getNavigateToPricingPlans$,
  UserSubject$,
} from '../Observables/User.observable';
import { getUserHelper } from '../Shared/memberAuthorizationHelper';
import { getCurrentSelectedId } from './Search.service';
import { setSelectedFolder } from './SelectedFolder.service';

export const setUser = (User: any) => {
  setUser$(User);
};

export const getUser = () => {
  return getUser$();
};

export const getRequestLogin = () => getRequestLogin$();

export const setRequestLogin = (requestLogin: any) =>
  setRequestLogin$(requestLogin);

export const getNavigateToPricingPlans = () => getNavigateToPricingPlans$();

export const setNavigateToPricingPlans = (navigateToPricingPlans: any) =>
  setNavigateToPricingPlans$(navigateToPricingPlans);
