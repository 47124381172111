import React, { useCallback, useEffect, useState } from 'react';
import LastUpdatedComponent from './TableColumns/LastUpdatedComponent';
import ViewsComponent from './TableColumns/ViewsComponent';
import FavoritesComponent from './TableColumns/FavoritesComponent';
import ContributorsComponent from './TableColumns/ContributorsComponent';
import { st, classes } from './TableHeader.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';
import NameComponent from './TableColumns/NameComponent';
import CheckBoxComponent from '../CheckBoxComponent';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import { TableColumns } from '../../../../../../Constants/TableHeader.Constants';
import { getSelectedItems } from '../../../../../Services/SelectedItems.service';
import { getSort, setSort } from '../../../../../Services/Sort.service';
import { getTableSettings } from '../../../../../Services/TableSettings.service';
import { getSelectedFolder } from '../../../../../Services/SelectedFolder.service';
import { changeSortDirection } from './Sort/helpers';
import { getRootFolder } from '../../../../../Services/RootFolder.service';
import { isDisableTableHeader } from '../../../../../Shared/accessModalsHelper';
import {
  getAppSettings,
  getSettingsData,
} from '../../../../../Services/AppSettings.service';
import SortWrapper from './Sort/SortWrapper';
import { getIsServerError } from '../../../../../Services/ServerError.service';

const TableHeader = () => {
  const [, selectedItems]: any = useObservable(getSelectedItems());
  const [, sortObj]: any = useObservable(getSort());
  const [, tableSettings]: any = useObservable(getTableSettings());
  const [, rootFolder]: any = useObservable(getRootFolder());
  const [, selectedFolder]: any = useObservable(getSelectedFolder());
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, serverError]: any = useObservable(getIsServerError());
  const [, settingsData]: any = useObservable(getSettingsData());
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const [isShowCheckBox, setIsShowCheckBox] = useState(false);
  const [state, setState] = useState({
    isShowLastUpdated: false,
    isShowViews: false,
    isShowFavorites: false,
    isShowContributors: false,
  });
  const {
    sortBy = settingsData?.settings?.displaySettings?.defaultSort,
    orientation = settingsData?.settings?.displaySettings?.defaultOrientation,
  } = { ...sortObj } || {};
  const handleTitleColumnOnClick = ({ sortBy: aSortBy, selectedId }: any) => {
    if (aSortBy === selectedId) {
      const tempOrientation = changeSortDirection({ orientation });
      setSort({ sortBy: selectedId, orientation: tempOrientation });
    } else {
      setSort({ sortBy: selectedId, orientation });
    }
  };

  const { t }: { t: any } = useTranslation();
  const name: string = t('app.widget.table.header.Name');
  const LastUpdates: string = t('app.widget.table.header.LastUpdated');
  const Views: string = t('app.widget.table.header.Views');
  const Favorites: string = t('app.widget.table.header.Favorites');
  const Contributors: string = t('app.widget.table.header.Contributors');
  const disabledTableHeader = isDisableTableHeader({
    rootFolder,
    selectedFolder,
    serverError,
  });
  const _setIsShown = useCallback(() => {
    const tempIsShowLastUpdated = tableSettings.filter((item: any) => {
      if (item.id === TableColumns.LAST_UPDATED) {
        return item;
      }
      return null;
    });
    const tempIsShowViews = tableSettings.filter((item: any) => {
      if (item.id === TableColumns.VIEWS_COUNTER) {
        return item;
      }
      return null;
    });
    const tempIsShowFavorites = tableSettings.filter((item: any) => {
      if (item.id === TableColumns.FAVORITES) {
        return item;
      }
      return null;
    });
    const tempIsShowContributors = tableSettings.filter((item: any) => {
      if (item.id === TableColumns.CONTRIBUTORS) {
        return item;
      }
      return null;
    });
    if (
      tempIsShowLastUpdated &&
      tempIsShowLastUpdated.length > 0 &&
      tempIsShowViews &&
      tempIsShowViews.length > 0 &&
      tempIsShowFavorites &&
      tempIsShowFavorites.length > 0 &&
      tempIsShowContributors &&
      tempIsShowContributors.length > 0
    ) {
      setState((prev) => ({
        ...prev,
        isShowLastUpdated: tempIsShowLastUpdated[0].value,
        isShowViews: tempIsShowViews[0].value,
        isShowFavorites: tempIsShowFavorites[0].value,
        isShowContributors: tempIsShowContributors[0].value,
      }));
    }
  }, [tableSettings]);
  useEffect(() => {
    if (tableSettings && tableSettings.length > 0) {
      _setIsShown();
    }
    if (selectedItems && selectedItems.length > 0) {
      setIsShowCheckBox(false);
    }
  }, [_setIsShown, selectedItems, tableSettings]);
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  const isMembersArea = appSettings?.isMembersArea ?? false;

  return !isMobile ? (
    <div
      tabIndex={0}
      aria-label="Sorting options"
      className={st(classes.tableHeaderContainer, { isEditorX })}
      onMouseEnter={() => setIsShowCheckBox(true)}
      onMouseLeave={() => setIsShowCheckBox(false)}
    >
      {!isMembersArea && (
        <div className={classes.tableHeaderContainerCheckBox}>
          {selectedItems &&
          selectedItems.length > 0 &&
          isShowCheckBox === false ? (
            <CheckBoxComponent />
          ) : (
            <div>
              <CheckBoxComponent disabledCheckBox={disabledTableHeader} />
            </div>
          )}
        </div>
      )}
      <div className={st(classes.tableHeaderContainerName, { isMembersArea })}>
        <NameComponent
          disabledTableHeader={disabledTableHeader}
          name={name}
          orientation={orientation}
          sortBy={sortBy}
          handleTitleColumnOnClick={handleTitleColumnOnClick}
          isMembersArea={isMembersArea}
        />
      </div>
      {state.isShowLastUpdated ? (
        <div className={classes.tableHeaderContainerLastUpdated}>
          <LastUpdatedComponent
            disabledTableHeader={disabledTableHeader}
            name={LastUpdates}
            orientation={orientation}
            sortBy={sortBy}
            handleTitleColumnOnClick={handleTitleColumnOnClick}
            isMembersArea={isMembersArea}
          />
        </div>
      ) : null}
      {state.isShowViews ? (
        <div className={classes.tableHeaderContainerViews}>
          <ViewsComponent
            disabledTableHeader={disabledTableHeader}
            name={Views}
            orientation={orientation}
            sortBy={sortBy}
            handleTitleColumnOnClick={handleTitleColumnOnClick}
            isMembersArea={isMembersArea}
          />
        </div>
      ) : null}
      {state.isShowFavorites ? (
        <div className={classes.tableHeaderContainerFavorites}>
          <FavoritesComponent
            disabledTableHeader={disabledTableHeader}
            name={Favorites}
            orientation={orientation}
            sortBy={sortBy}
            handleTitleColumnOnClick={handleTitleColumnOnClick}
            isMembersArea={isMembersArea}
          />
        </div>
      ) : null}
      {state.isShowContributors ? (
        <div className={classes.tableHeaderContainerContributors}>
          <ContributorsComponent
            name={Contributors}
            isMembersArea={isMembersArea}
          />
        </div>
      ) : null}
      <SortWrapper />
    </div>
  ) : (
    <div></div>
  );
};

export default TableHeader;
