import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { useCallback, useEffect, useState } from 'react';
import { Text, TextField, TextFieldTheme } from 'wix-ui-tpa';
import { ITEMTYPE } from '../../../../../../../Constants/ItemType.Constants';
import {
  renameItem,
  setRenameItemStatus,
  setRenamingItem,
} from '../../../../../../Services/ItemRename.service';
import { setIsLoading } from '../../../../../../Services/Loading.service';
import { st, classes } from './ItemName.st.css';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';

const ItemNameTitle = (props: any) => {
  const { t }: { t: any } = useTranslation();
  const [, AppSettings]: any = useObservable(getAppSettings());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const isMembersArea = AppSettings?.isMembersArea ?? false;

  const { item, renameInfo } = props;
  const [state, setState] = useState({
    value: item.name,
    renamingValue: '',
    defaultRenamingValue: '',
    tempExtension: '',
  });

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        state.renamingValue !== '' &&
        state.renamingValue !== state.defaultRenamingValue
      ) {
        saveRenameItem();
      } else {
        cancelRenameItem();
      }
    },
    [state],
  );

  const checkIfFile = () => {
    let tempResult = '';
    let tempExtension = '';
    if (ITEMTYPE.FOLDER in item) {
      tempResult = item.name;
    } else if (ITEMTYPE.FILE in item) {
      tempExtension = item?.fileFields?.extension;
      tempResult = item.name.substring(
        0,
        item.name.indexOf(`.${tempExtension}`),
      );
    }
    setState((prev) => ({
      ...prev,
      renamingValue: tempResult,
      defaultRenamingValue: tempResult,
      tempExtension,
    }));
  };

  useEffect(() => {
    checkIfFile();
  }, []);
  const saveRenameItem = () => {
    if (state.renamingValue === '') {
      setState((prev) => ({
        ...prev,
        value: state.value,
      }));
      return;
    } else {
      setState((prev) => ({
        ...prev,
        value:
          state.tempExtension === ''
            ? state.renamingValue
            : state.renamingValue + '.' + state.tempExtension,
        renamingValue: state.renamingValue,
        defaultRenamingValue: state.renamingValue,
      }));
      setIsLoading(true);
      renameItem(t, item);
    }
  };

  const cancelRenameItem = () => {
    setState((prev) => ({
      ...prev,
      renamingValue: state.defaultRenamingValue,
      defaultRenamingValue: state.defaultRenamingValue,
      value: state.value,
    }));
    setRenameItemStatus({
      folderId: '',
      renamingStatus: false,
      parentFolderId: '',
      newName: '',
    });
  };

  return (
    <>
      {renameInfo &&
      renameInfo?.renamingStatus === true &&
      item.id === renameInfo?.folderId ? (
        <div id="textField" className={classes.renameInput}>
          <TextField
            className={st(classes.renameInputField, { isMobile })}
            theme={TextFieldTheme.Line}
            label=""
            value={state.renamingValue}
            autoFocus={true}
            onChange={(e) => {
              const currentRenamingValue = e.target.value;
              setTimeout(() => {
                setState((prev) => ({
                  ...prev,
                  renamingValue: currentRenamingValue,
                }));
                setRenamingItem(currentRenamingValue);
              }, 10);
            }}
            onBlur={(event) => {
              if (
                state.value !== '' &&
                renameInfo &&
                item.id === renameInfo.folderId &&
                renameInfo.renamingStatus === true
              ) {
                handleClickOutside(event);
              }
            }}
            onKeyDown={(event) => {
              if (event.key === 'Escape') {
                cancelRenameItem();
              } else if (event.key === 'Enter') {
                saveRenameItem();
              }
            }}
          />
        </div>
      ) : (
        <div className={classes.itemNameTitle} title={item.name}>
          <Text
            className={`${st(classes.titleFontAndColor, {
              isMobile,
              isMembersArea,
            })} ${classes.contentEllipsis}`}
          >
            {item.name}
          </Text>
        </div>
      )}
    </>
  );
};

export default ItemNameTitle;
