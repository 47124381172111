import React, { useEffect, useState } from 'react';
import { Divider } from 'wix-ui-tpa';
import ItemGenerator from './ItemGenerator/ItemGenerator';
import {
  st as dividerStyle,
  classes,
} from '../../../../../Shared/SharedDivider.st.css';
import {
  classes as itemWrapperClasses,
  style as st,
} from './ItemWrapper.st.css';
import useObservable from '../../../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../../../Services/SelectedItems.service';
import {
  getAppSettings,
  getSettingsData,
} from '../../../../../Services/AppSettings.service';
import { getTableSettings } from '../../../../../Services/TableSettings.service';
import { TableColumns } from '../../../../../../Constants/TableHeader.Constants';

const ItemWrapper = (props: any) => {
  const {
    item,
    setHoveredOrSelectedItems,
    hoveredOrSelectedItems,
    getPreviousItems,
    index,
  } = props;
  const [, settingsData]: any = useObservable(getSettingsData());
  const dividerWidth =
    settingsData?.settings?.designSettings?.dividerWidth ?? 1;
  const membersDividerWidth =
    settingsData?.settings?.designSettings?.memberProfileDividerWidth ?? 1;
  const [state, setState] = useState<any>({
    selectedItems: [],
  });

  const [, selectedItems]: any = useObservable(getSelectedItems());
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, tableDisplaySettings]: any = useObservable(getTableSettings());
  const displaySearch =
    tableDisplaySettings &&
    tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.SEARCH_BAR,
    );
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean = appSettings?.isEditor_X ?? false;
  const showTopDivider = isMobile
    ? !displaySearch?.value || (selectedItems && selectedItems.length > 0)
    : true;
  const isMembersArea = appSettings?.isMembersArea ?? false;

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      let tempSelectedItems: any = [];
      const previousSelectedItem = getPreviousItems(selectedItems);
      tempSelectedItems = [...previousSelectedItem, ...selectedItems];
      setState((prev: any) => ({
        ...prev,
        selectedItems: tempSelectedItems,
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        selectedItems: [],
      }));
    }
  }, [getPreviousItems, selectedItems]);

  return (
    <div
      onMouseEnter={() => {
        setHoveredOrSelectedItems('MouseEnter', item.id);
      }}
      onMouseLeave={() => {
        setHoveredOrSelectedItems('MouseLeave', item.id);
      }}
    >
      <div
        className={st(
          itemWrapperClasses.root,
          {
            checked: selectedItems && selectedItems.includes(item.id),
            isMembersArea,
          },
          itemWrapperClasses.itemWrapperGeneratorContainer,
        )}
      >
        {index === 0 && showTopDivider ? (
          <div>
            <Divider
              className={
                hoveredOrSelectedItems.includes(item.id) ||
                (state.selectedItems && state.selectedItems.includes(item.id))
                  ? dividerStyle(
                      classes[
                        `dividerStyle${
                          isMembersArea ? membersDividerWidth : dividerWidth
                        }`
                      ],
                    )
                  : dividerStyle(
                      classes.dividerStyle,
                      { isEditorX, isMobile },
                      classes[
                        `dividerStyle${
                          isMembersArea ? membersDividerWidth : dividerWidth
                        }`
                      ],
                    )
              }
            />
          </div>
        ) : null}
        <ItemGenerator item={item} />
      </div>
      <div className={classes.dividerContainer}>
        <Divider
          className={
            hoveredOrSelectedItems.includes(item.id) ||
            (state.selectedItems && state.selectedItems.includes(item.id))
              ? dividerStyle(
                  classes[
                    `dividerStyle${
                      isMembersArea ? membersDividerWidth : dividerWidth
                    }`
                  ],
                )
              : dividerStyle(
                  classes.dividerStyle,
                  { isEditorX, isMobile },
                  classes[
                    `dividerStyle${
                      isMembersArea ? membersDividerWidth : dividerWidth
                    }`
                  ],
                )
          }
        />
      </div>
    </div>
  );
};

export default ItemWrapper;
