import React, { useEffect, useState, useCallback } from 'react';
import ItemWrapper from './Item/ItemWrapper';
import { SkeletonItemWrapper } from './SkeletonItemWrapper/SkeletonItemWrapper';
import useObservable from '../../../../../Shared/Hooks/useObservable.Hook';
import EmptyFolder from '../../../../Shared/EmptyStates/EmptyFolder/EmptyFolder';
import {
  getSelectedFolder,
  getSelectedFolderId,
  loadMoreFolderItems,
} from '../../../../Services/SelectedFolder.service';
import {
  AddFolderStatus,
  getAddFolderStatus,
} from '../../../../Services/AddNewFolder.service';
import { getRootFolder$ } from '../../../../Observables/RootFolder.observable';
import { getSearchSubject } from '../../../../Services/Search.service';
import NoResults from '../../../../Shared/EmptyStates/NoResults/NoResults';
import { getUploadingFileStatus } from '../../../../Services/Upload/Upload.service';
import { FileSkeletonWrapper } from './FileSkeletonWrapper/FileSkeletonWrapper';
import { getIsLoading } from '../../../../Services/Loading.service';
import EmptyLibrary from '../../../../Shared/EmptyStates/EmptyLibrary/EmptyLibrary';
import { Reason } from '@wix/ambassador-file-sharing-v1-library-item/types';
import ItemNotFound from '../../../../Shared/EmptyStates/ItemNotFound/ItemNotFound';

const ItemsWrapper = () => {
  const [, addFolderStatus] = useObservable(getAddFolderStatus());
  const [, selectedFolder]: any = useObservable(getSelectedFolder());
  const [, rootFolder]: any = useObservable(getRootFolder$());
  const [, searchSubject]: any = useObservable(getSearchSubject());
  const [, uploadingFiles]: any = useObservable(getUploadingFileStatus());
  const [, isLoading = true]: any = useObservable(getIsLoading());
  const [, selectedFolderId]: any = useObservable(getSelectedFolderId());

  const [state, setState] = useState<any>({
    libraryItems: undefined,
    sort: null,
    metaData: null,
    tableSettings: [],
    hoveredOrSelectedItems: [],
  });

  const setHoveredOrSelectedItems = (type: string, itemId: string) => {
    let tempHoveredOrSelectedItems: any = [];
    const currentItemId = itemId;
    const currentItemIndex = state.libraryItems.findIndex(
      (item: any) => item.id === currentItemId,
    );
    let previousItemId;
    if (currentItemIndex > 0) {
      previousItemId = state.libraryItems[currentItemIndex - 1].id;
    }
    if (type === 'MouseEnter') {
      tempHoveredOrSelectedItems = [];
      tempHoveredOrSelectedItems.push(currentItemId);
      if (currentItemIndex > 0) {
        tempHoveredOrSelectedItems.push(previousItemId);
      }
    }
    setState((prev: any) => ({
      ...prev,
      hoveredOrSelectedItems: tempHoveredOrSelectedItems,
    }));
  };

  const getPreviousItems = (selectedItems: []) => {
    const tempPreviousSelectedItemId: any = [];
    selectedItems.forEach((selectedItemId) => {
      const currentItemIndex = state.libraryItems.findIndex(
        (item: any) => item.id === selectedItemId,
      );
      if (currentItemIndex > 0) {
        const previousItemId = state.libraryItems[currentItemIndex - 1].id;
        tempPreviousSelectedItemId.push(previousItemId);
      }
    });
    return tempPreviousSelectedItemId;
  };

  const generateItems = () => {
    if (state.libraryItems && state.libraryItems.length > 0) {
      return state.libraryItems.map((item: any, index: number) => {
        return (
          <ItemWrapper
            index={index}
            getPreviousItems={getPreviousItems}
            hoveredOrSelectedItems={state.hoveredOrSelectedItems}
            setHoveredOrSelectedItems={setHoveredOrSelectedItems}
            item={item}
            key={item.id}
            tableSettings={state.tableSettings}
          />
        );
      });
    }
  };

  const isBottom = (el: any) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 500;
  };

  const trackScrolling = useCallback(() => {
    const wrappedElement = document.getElementById('table');
    if (isBottom(wrappedElement)) {
      if (
        state.libraryItems.length > 0 &&
        state.metaData &&
        state.metaData.nextCursor
      ) {
        loadMoreFolderItems(
          state.libraryItems[0].parentFolderId,
          state.metaData.nextCursor,
        );
      }
      document.removeEventListener('scroll', trackScrolling);
    }
  }, [state.libraryItems, state.metaData]);

  useEffect(() => {
    if (selectedFolder && selectedFolder.libraryItems) {
      setState((prev: any) => ({
        ...prev,
        libraryItems: selectedFolder.libraryItems,
        metaData: selectedFolder.metaData,
      }));
      if (selectedFolder.libraryItems.length > 0) {
        document.addEventListener('scroll', trackScrolling);
      }
    }
    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  }, [selectedFolder, trackScrolling]);
  const libraryItemsLength = state?.libraryItems?.length ?? 0;
  let condition = 0;

  if (rootFolder === undefined || selectedFolder === undefined || isLoading) {
    condition = 4;
  } else if (libraryItemsLength > 0) {
    condition = 1;
    if (libraryItemsLength === 1) {
      const isNotFound =
        selectedFolder?.libraryItems?.[0]?.authorizeActions?.find(
          (item: any) => item.action === 'VIEW',
        )?.reason === Reason.NOT_FOUND ?? false;
      if (isNotFound) {
        condition = 5;
      }
    }
  } else if (libraryItemsLength === 0 && searchSubject?.value !== '') {
    condition = 2;
  } else if (
    libraryItemsLength === 0 &&
    addFolderStatus === AddFolderStatus.CLOSED &&
    uploadingFiles !== undefined &&
    uploadingFiles.status !== 'OPENED'
  ) {
    condition = 3;
  } else if (state?.libraryItems === undefined) {
    condition = 4;
  }
  return (
    <>
      {uploadingFiles !== undefined &&
        uploadingFiles.status === 'OPENED' &&
        uploadingFiles.libraryItems.length > 0 && (
          <FileSkeletonWrapper status={addFolderStatus} />
        )}
      {addFolderStatus !== AddFolderStatus.CLOSED && (
        <SkeletonItemWrapper status={addFolderStatus} />
      )}
      {(() => {
        switch (condition) {
          case 1:
            return (
              <div id="table" tabIndex={0} aria-label="Items list">
                {generateItems()}
              </div>
            );
          case 2:
            return <NoResults />;
          case 3:
            return (
              <>
                {selectedFolderId === rootFolder.id ? (
                  <EmptyLibrary />
                ) : (
                  <EmptyFolder />
                )}
              </>
            );
          case 5:
            return <ItemNotFound />;
          case 4:
          default:
            return <></>;
        }
      })()}
    </>
  );
};

export default ItemsWrapper;
