import React from 'react';
import { st, classes } from './EmptyStates.st.css';
import { Button, ButtonPriority, Divider, Text } from 'wix-ui-tpa';
import useObservable from '../../../Shared/Hooks/useObservable.Hook';
import {
  getAppSettings,
  getSettingsData,
} from '../../Services/AppSettings.service';
import {
  classes as dividerClasses,
  st as stSharedDivider,
} from '../SharedDivider.st.css';
import FileButton from '../../Components/FolderTable/HeaderWrapper/ToolBarWrapper/MainToolBar/FileButton/FileButton';

const EmptyStates = ({
  title,
  subTitle,
  primaryText,
  secondaryText,
  primaryButtonOnClick,
  secondaryButtonOnClick,
  showInModal = false,
  showFileButton = false,
  uploadFilePermissions = {},
  isUploadFileDisabled = false,
}: any) => {
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, settingsData]: any = useObservable(getSettingsData());
  const borderRadius =
    settingsData?.settings?.designSettings?.buttonCornerRadius ?? 0;
  const dividerWidth =
    settingsData?.settings?.designSettings?.dividerWidth ?? 1;
  const isMobile = appSettings !== undefined ? appSettings?.isMobile : '';
  const isEditorX: boolean =
    appSettings !== undefined ? appSettings.isEditor_X : false;
  return (
    <>
      {!showInModal && !isMobile && (
        <Divider
          className={stSharedDivider(
            dividerClasses.dividerStyle,
            {
              isEditorX,
              isMobile,
            },
            dividerClasses[`dividerStyle${dividerWidth}`],
          )}
        />
      )}
      <div
        className={st(classes.emptyStatesContainer, {
          isMobile,
          isModal: showInModal,
        })}
      >
        <Text
          className={st(classes.emptyStatesTitle, {
            isModal: showInModal,
            isMobile,
          })}
        >
          {title}
        </Text>
        <Text
          className={st(classes.emptyStatesSubTitle, { isModal: showInModal })}
        >
          {subTitle}
        </Text>
        <div
          className={
            isMobile
              ? classes.emptyStatesBtn_container_mobile
              : classes.emptyStatesBtn_container
          }
        >
          {primaryText && (
            <Button
              priority={ButtonPriority.basicSecondary}
              onClick={primaryButtonOnClick}
              className={st(classes.secondaryButtonContainer, {
                isModal: showInModal,
              })}
              style={{ borderRadius }}
            >
              {primaryText}
            </Button>
          )}
          {secondaryText && (
            <Button
              className={st(classes.primaryButtonContainer, {
                isModal: showInModal,
              })}
              onClick={secondaryButtonOnClick}
              style={{ borderRadius }}
            >
              {secondaryText}
            </Button>
          )}
        </div>
        {showFileButton && (
          <FileButton
            uploadFilePermissions={uploadFilePermissions}
            isUploadFileDisabled={isUploadFileDisabled}
            isMobile={isMobile}
          />
        )}
      </div>
    </>
  );
};
export default EmptyStates;
