import React, { useEffect, useRef, useState, useCallback } from 'react';
import { classes } from '../TableHeader.st.css';
import { IconTooltip } from 'wix-ui-tpa';
import Sort from 'wix-ui-icons-common/on-stage/Sort';
import ActionsMenuLayoutComponent from './ActionsMenuLayoutComponent';
import { isDisableTableHeader } from '../../../../../../Shared/accessModalsHelper';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getRootFolder } from '../../../../../../Services/RootFolder.service';
import { getSelectedFolder } from '../../../../../../Services/SelectedFolder.service';
import { getSort, setSort } from '../../../../../../Services/Sort.service';
import { getIsServerError } from '../../../../../../Services/ServerError.service';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  getAppSettings,
  getSettingsData,
} from '../../../../../../Services/AppSettings.service';

const SortWrapper = () => {
  const container: any = useRef();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [, rootFolder]: any = useObservable(getRootFolder());
  const [, selectedFolder]: any = useObservable(getSelectedFolder());
  const [, sortObj]: any = useObservable(getSort());
  const [, serverError]: any = useObservable(getIsServerError());
  const [, AppSettings]: any = useObservable(getAppSettings());
  const [, settingsData]: any = useObservable(getSettingsData());
  const isMobile = AppSettings !== undefined ? AppSettings?.isMobile : '';
  const isMembersArea = AppSettings?.isMembersArea ?? false;

  useEffect(() => {
    if (settingsData?.settings?.displaySettings) {
      const { defaultSort, defaultOrientation } =
        settingsData?.settings?.displaySettings;
      setSort({ sortBy: defaultSort, orientation: defaultOrientation });
    }
  }, [settingsData?.settings?.displaySettings]);
  const disabledTableHeader = isDisableTableHeader({
    rootFolder,
    selectedFolder,
    serverError,
  });
  const handleOpenDropDown = (state: boolean) => {
    setIsOpen(!state);
  };
  const handleClickOutside = (event: any) => {
    if (container.current && !container.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOnKeyDown = (event: any) => {
    const enterBarKey = 'Enter';
    if (event.key === 'Escape') {
      handleOpenDropDown(true);
    }
    if (event.key === enterBarKey) {
      handleOpenDropDown(isOpen);
    }
  };

  useEffect(() => {
    const sortItem = document.getElementById('sortContainer');
    sortItem
      ?.querySelector(':nth-child(1) > button')
      ?.setAttribute('tabIndex', '-1');
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={classes.sortMenuContainer}
      ref={container}
      id="sortContainer"
      aria-label="sort by menu"
      aria-haspopup="true"
      aria-expanded={isOpen}
      typeof="button"
      tabIndex={0}
      role="button"
      onKeyDown={handleOnKeyDown}
    >
      <IconTooltip
        message={t('app.widget.sort.tooltip')}
        showTooltip={showTooltip}
        onMouseEnter={() =>
          isMobile !== undefined && !isMobile ? setShowTooltip(true) : null
        }
        onMouseLeave={() => setShowTooltip(false)}
        disabled={disabledTableHeader}
        icon={
          <Sort
            className={
              classes.sortIcon +
              ' ' +
              (disabledTableHeader ? classes.disabledSortIcon : '')
            }
          />
        }
        onClick={() => handleOpenDropDown(isOpen)}
      />
      {isOpen && !disabledTableHeader && (
        <div className={classes.sortMenu} onKeyDown={handleOnKeyDown}>
          <ActionsMenuLayoutComponent
            isMobile={isMobile}
            orientation={sortObj.orientation}
            sortBy={sortObj.sortBy}
            setIsOpen={setIsOpen}
            isMembersArea={isMembersArea}
          />
        </div>
      )}
    </div>
  );
};

export default SortWrapper;
