import {
  LibraryItemSortBy,
  Orientation,
} from '@wix/ambassador-file-sharing-v1-settings/types';

const getSortIds = () => [
  LibraryItemSortBy.NAME,
  LibraryItemSortBy.LAST_MODIFIED,
  LibraryItemSortBy.UNIQUE_VIEWS,
  LibraryItemSortBy.TIMES_FAVORITED,
  LibraryItemSortBy.TYPE,
  LibraryItemSortBy.SIZE,
];
const getSortLabels = (t: any) => [
  t('app.widget.sort.name'),
  t('app.widget.sort.last_modified'),
  t('app.widget.sort.views_count'),
  t('app.widget.sort.favorites'),
  t('app.widget.sort.type'),
  t('app.widget.sort.size'),
];
const changeSortDirection = ({ orientation }: any) => {
  if (orientation === Orientation.DESC) {
    return Orientation.ASC;
  } else if (orientation === Orientation.ASC) {
    return Orientation.DESC;
  }
};
const getButtonDescriptionBi = (selectedId: string) => {
  switch (selectedId) {
    case LibraryItemSortBy.NAME:
      return 'name';
    case LibraryItemSortBy.TYPE:
      return 'type';
    case LibraryItemSortBy.TIMES_FAVORITED:
      return 'stars';
    case LibraryItemSortBy.UNIQUE_VIEWS:
      return 'views';
    case LibraryItemSortBy.CREATED_AT:
      return 'date';
    default:
  }
};
export {
  getSortIds,
  getSortLabels,
  changeSortDirection,
  getButtonDescriptionBi,
};
