import React from 'react';
import { Text, TextButton } from 'wix-ui-tpa';
import { Trans } from '@wix/yoshi-flow-editor';
import { navigateToParentFolder } from '../../../../../../Services/Search.service';
import { st, classes } from '../../../../../../Shared/SharedText.st.css';
import { classes as itemNameClasses } from './ItemName.st.css';

const SearchSubtitle = (props: any) => {
  const { item, isMobile } = props;

  const parentFolderObj =
    item?.path?.length > 1 ? item.path[item.path.length - 1] : {};

  return (
    <>
      <Text
        className={
          st(classes._textColor, { isMobile }) +
          ' ' +
          itemNameClasses.searchSubtitle
        }
      >
        <Trans
          i18nKey={
            isMobile
              ? 'app.widget.search.in.folder.mobile'
              : 'app.widget.search.in.folder'
          }
          values={{ folder_name: parentFolderObj?.name }}
        >
          <TextButton
            contentClassName={classes.contentEllipsis}
            className={
              st(classes.textButtonAndDividersColor, { isMobile }) +
              ' ' +
              itemNameClasses.textButtonWrapper
            }
            title={parentFolderObj?.name}
            onClick={(e) => {
              if (parentFolderObj?.libraryItemId) {
                e.stopPropagation();
                navigateToParentFolder(item);
              }
            }}
          ></TextButton>
        </Trans>
      </Text>
    </>
  );
};

export default SearchSubtitle;
