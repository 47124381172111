import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ActionsMenuLayout } from 'wix-ui-tpa';
import {
  getManageOptions,
  getReportOption,
  getViewDownloadOptions,
} from './helper';
import { ITEM_MENU_OPTIONS } from '../../../../../../../../../Constants/ItemMenu.Constant';
import { st, classes } from '../ItemActionsMenu.st.css';
import useObservable from '../../../../../../../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../../../../../../../Services/AppSettings.service';

export const MenuOptions = ({
  handleOnClick,
  canManage,
  item,
  showPermissions,
}: any) => {
  const { t } = useTranslation();
  const [, AppSettings]: any = useObservable(getAppSettings());
  const isMembersArea = AppSettings?.isMembersArea ?? false;
  const isMobile = AppSettings?.isMobile ?? '';
  let options = [];

  const generateOptions = () => {
    const tempView = getViewDownloadOptions(t, isMembersArea);
    const tempReport = getReportOption(t);
    const dividerOption = { type: ITEM_MENU_OPTIONS.DIVIDER };
    let tempManage;
    if (canManage) {
      tempManage = getManageOptions(t, showPermissions, isMembersArea);
      options = [
        ...tempView,
        dividerOption,
        ...tempManage,
        dividerOption,
        ...tempReport,
      ];
    } else {
      options = [...tempView, dividerOption, ...tempReport];
    }
    let disabled = false;
    return options.map((option: any, index: number) => {
      disabled =
        option.type === ITEM_MENU_OPTIONS.DOWNLOAD &&
        item?.folderFields?.childrenCount <= 0;
      if (option.type === ITEM_MENU_OPTIONS.DIVIDER) {
        return <ActionsMenuLayout.Divider />;
      }
      return (
        <ActionsMenuLayout.Item
          content={option.content}
          onClick={() => handleOnClick(option.type)}
          disabled={disabled}
        />
      );
    });
  };

  return (
    <ActionsMenuLayout
      className={st(classes.actionsMenuLayoutContainer, {
        isMobile,
        isMembersArea,
      })}
    >
      {generateOptions()}
    </ActionsMenuLayout>
  );
};
