import React from 'react';
import { BadgeWithTooltipType } from './ItemAvailabilityTypes';
import { Badge, Popover, Text, BadgePriority } from 'wix-ui-tpa';
import { classes } from './ItemAvailabilityBadges.st.css';
import useObservable from '../../../../../../../Shared/Hooks/useObservable.Hook';
import { getAppSettings } from '../../../../../../Services/AppSettings.service';

export const BadgeWithTooltip = (props: BadgeWithTooltipType) => {
  const { label, tooltip, prefixIcon } = props;
  const [, appSettings]: any = useObservable(getAppSettings());
  const isMobile = appSettings?.isMobile ?? false;

  const getBadgeComponent = () => {
    return (
      <Badge
        className={classes.badgeWrapper}
        priority={BadgePriority.light}
        icon={prefixIcon}
      >
        <Text className={classes.badgeLabel}>{label}</Text>
      </Badge>
    );
  };
  return (
    <>
      {isMobile ? (
        getBadgeComponent()
      ) : (
        <Popover placement="top" minWidth="293px">
          <Popover.Element>{getBadgeComponent()}</Popover.Element>
          {!isMobile && (
            <Popover.Content>
              <Text>{tooltip}</Text>
            </Popover.Content>
          )}
        </Popover>
      )}
    </>
  );
};
