import React from 'react';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getIsLoading } from '../../../Services/Loading.service';
import { getIsServerError } from '../../../Services/ServerError.service';
import ErrorState from '../../../Shared/EmptyStates/ErrorState/ErrorState';
import LoadingWrapper from '../../../Shared/LoadingWrapper/LoadingWrapper';
import ItemsWrapper from './ItemsWrapper/ItemsWrapper';
import TableHeaderWrapper from './TableHeaderWrapper/TableHeaderWrapper';
import { classes, st } from './TableWrapper.st.css';
import { getRootFolder } from '../../../Services/RootFolder.service';
import { Availability, Status } from '../../../../Constants/Permissions';
import AccessModalsWrapper from '../../../Shared/EmptyStates/AccessModalsWrapper/AccessModalsWrapper';

const TableWrapper = () => {
  const [, isLoading]: any = useObservable(getIsLoading());
  const [, isServerError]: any = useObservable(getIsServerError());
  const [, rootFolder]: any = useObservable(getRootFolder());
  const viewPermissions =
    rootFolder &&
    rootFolder.authorizeActions?.find(
      (itemData: any) => itemData.action === 'VIEW',
    );
  return (
    <>
      <div>
        <TableHeaderWrapper />
      </div>

      {isServerError && isServerError.isError === true ? (
        <ErrorState />
      ) : isLoading !== undefined ? (
        <div className={classes.tableContainer}>
          {isLoading === true ? (
            <>
              <div
                className={st(classes.tableContentLoading, { isLoading: true })}
              >
                <ItemsWrapper />
              </div>
              <div className={classes.tableLoading}>
                <LoadingWrapper />
              </div>
            </>
          ) : (
            <div className={st(classes.tableContent, { isLoading: false })}>
              {viewPermissions &&
              viewPermissions.status === Status.FORBIDDEN ? (
                <AccessModalsWrapper
                  folderName={rootFolder && rootFolder?.value}
                  viewPermissions={viewPermissions}
                  availability={
                    rootFolder?.noPlansAvailable === false ||
                    rootFolder?.pricingPlansIds?.length
                      ? Availability.PAID
                      : Availability.MEMBER
                  }
                />
              ) : (
                <ItemsWrapper />
              )}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default TableWrapper;
