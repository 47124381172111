import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type ISettingsParams = {
  settingsAPIData: SettingsParamType.Object;
};

export default createSettingsParams<ISettingsParams>({
   settingsAPIData: {
    type: SettingsParamType.Object,
    getDefaultValue: () => ({}),
  },

});
